import React, { useState, useEffect, useContext } from 'react';
import logo from './assets/logo.png';
import './Login.css';
import AppInstallHeader from './AppInstallHeader';
import axios from 'axios';
import { API_URL } from './Constants';
import { UserContext } from './context/UserContext';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      onLogin();
    }
  }, [onLogin]);

  const setLoginButtonActive = (isActive) => {
    const loginButton = document.getElementById('login-button');
    loginButton.disabled = !isActive;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginButtonActive(false);

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    try {
      const response = await axios.post(
        `${API_URL}/api/auth/login`,
        formData,
      { 
        withCredentials: true,
      }
      );
      
      if (response.data.error === true || !response.data.token) {
        throw new Error('Usuario o contraseña inválidos');
      };
      const token = response.data.token;
      localStorage.setItem('token', token);
      setUser(response.data.firstname);

      onLogin();
    } catch (error) {
      const errorMessage = document.getElementById('error-message');
      errorMessage.innerHTML = 'Usuario o contraseña inválidos'
      // errorMessage.innerHTML = error.message;
      setLoginButtonActive(true);
    }
  };

  return (
    <div className="login-container">
      <AppInstallHeader />
      <header className="login-header">
        <img src={logo} className="login-logo" alt="logo" />
        <h2>Anomalías</h2>
      </header>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Usuario:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <span id="error-message" className="error-message"></span>
        <button id="login-button" type="submit">Conectarse</button>
      </form>
    </div>
  );
}

export default Login;
