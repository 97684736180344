import React, { useState, useContext } from 'react';
import './Header.css';
import AppInstallHeader from './AppInstallHeader';
import HeaderBanner from './HeaderBanner';
import { UserContext } from './context/UserContext';
import icon_back from './assets/icon_back.png';

function Header({ currentPage, onLogout, onBack }) {
  const { user } = useContext(UserContext);
  return (
    <div className="header-container">
      <HeaderBanner />
      {/* <AppInstallHeader /> */}
      <header className="App-header avatar-text">
        {currentPage === 'MainMenu' ?
          (
            <>
              <div style={{ paddingTop: '10px' }}>Hola, {user}</div>
              <button
                onClick={onLogout} 
                style={{ 
                  background: 'none', 
                  border: 'none', 
                  color: 'rgb(135,165,88)', 
                  textDecoration: 'none', 
                  cursor: 'pointer', 
                  padding: 0 
                }}
              >
                Salir
              </button>
            </>
          ) : (
            <img src={icon_back} alt="Back" onClick={onBack} style={{ height: '24px', cursor: 'pointer', paddingTop: '10px' }} />
          )
        }
      </header>
    </div>
  );
}

export default Header;
