import React, { useState, useEffect } from 'react';

import './Message.css';
import '../MainMenu.css';
import Header from '../Header';
import icon_camera from '../assets/icon_camera.png';
import icon_view from '../assets/icon_view.png';
import icon_send_response from '../assets/icon_send_response.png';
import axiosInstance from '../axiosConfig'
import { saveFormDataInIdb, getFormDataFromIdb, deleteFormData } from '../helper/idb';
import { useNavigate, useLocation } from 'react-router-dom';
import { getIncidentById } from '../helper/api';

const MAX_IMAGES = 15;

function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function Message({ handleLogout }) {
    const [descripcion, setDescripcion] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [trabajoTerminado, setTrabajoTerminado] = useState('-');
    const [capturedImages, setCapturedImages] = useState([]);

    const location = useLocation();
    const message = location.state?.message;

    const isEditable = !message;

    useEffect(() => {
        if (message) {
            console.log('Anomaly:', message);
            setDescripcion(message.name);
        }
    }, [message]);

    const navigate = useNavigate();

    const clearAllFields = () => {
        // setVolume('');
        // setDate('');
    };

    const handleBack = () => {
        clearAllFields();
        navigate(-1);
    };

    const saveImagesByResponseId = async (id, capturedImages) => {
        try {
            for (const image of capturedImages) {
                const img = new Image();
                img.src = image;

                img.onload = async () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    let newWidth = originalWidth;
                    let newHeight = originalHeight;

                    if (originalWidth > originalHeight) {
                        if (originalWidth > 1024) {
                            newWidth = 1024;
                            newHeight = (originalHeight * 1024) / originalWidth;
                        }
                    } else {
                        if (originalHeight > 1024) {
                            newHeight = 1024;
                            newWidth = (originalWidth * 1024) / originalHeight;
                        }
                    }
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    const resizedImage = canvas.toDataURL('image/jpeg');
                    const byteString = atob(resizedImage.split(',')[1]);
                    const mimeString = 'image/jpeg';
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: mimeString });

                    const formData = new FormData();
                    formData.append('uploaded_file', blob, generateRandomString(6) + '.jpg');

                    try {
                        const response = await axiosInstance.post('/php/pwa_upload_image.php?messageId=' + id, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        if (response?.status !== 200) {
                            throw new Error('Network response was not ok');
                        }

                        const result = response.data;
                        console.log('Data saved successfully:', result);
                    } catch (error) {
                        console.error('Error saving data image:', error);
                        await saveFormDataInIdb({ incident_id: id, image });
                    }
                }
            }
        } catch (error) {
            await saveFormDataInIdb({ incident_id: id, image });
        }
    };

    useEffect(() => {
        const handleOnline = async () => {
            console.log('handleOnline called');
            // const formDataList = await getFormDataFromIdb();
            // console.log('formDataList', formDataList);
            // for (const data of formDataList) {
            //     if (data.body) {
            //         await saveAnomalyToServer(data.body);
            //         await saveImagesByIncidentId(data.id, data.images);
            //         await deleteFormData(data.id);
            //     } else {
            //         await saveImagesByIncidentId(data.incident_id, [data.image]);
            //         await deleteFormData(data.id);
            //     }
            // }
        };

        const checkForRecordsToSync = async () => {
            // if (navigator.onLine) {
            //     console.log('Online, checking for records to sync');
            //     await handleOnline();
            // } else {
            //     console.log('Offline, skipping sync');
            // }
        };

        checkForRecordsToSync();
    }, []);

    const getBody = () => {
        return {
            idMessage: message.id,
            comment: observaciones,
            date: Date.now() / 1000,
            utcOffset: '-3',
            finishesWork: trabajoTerminado,
            incidentId: message.incident_id,
        };
    }

    const generateForm = (body) => {
        const formDataReturn = new FormData();
        for (const key in body) {
            if (body.hasOwnProperty(key)) {
                formDataReturn.append(key, body[key]);
            }
        }
        return formDataReturn;
    }

    const saveMessageResponseToServer = async (body) => {
        try {
            const response = await axiosInstance.post('/php/pwa_save_message_response.php', generateForm(body));
            console.log('response', response);
            if (!response || response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            const result = response.data;
            await saveImagesByResponseId(result.id, capturedImages);
        } catch (error) {
            console.error('Error saving data: (catch else)', error);
            await saveFormDataInIdb({ body, images: capturedImages });
        }
    }

    const handleSave = async () => {
        let errors = false;
        console.log('observaciones', observaciones);
        if (observaciones.trim() === '') {
            errors = true;
            const errorMessage = document.getElementById('error-message-observaciones');
            errorMessage.innerHTML = 'La observación es requerida';
        }

        if (trabajoTerminado === '-') {
            errors = true;
            const errorMessage = document.getElementById('error-message-trabajo-terminado');
            errorMessage.innerHTML = 'Elija una opción';
        }

        if (errors) {
            return;
        }

        const body = getBody();

        await saveMessageResponseToServer(body);
        navigate('/main-menu');
    };

    const handleClickView = async () => {
        const anomaly = await getIncidentById(message.incident_id);
        navigate('/anomaly', { state: { anomaly } });
    }

    const handleTrabajoTerminadoChange = (e) => {
        const errorMessage = document.getElementById('error-message-trabajo-terminado');
        errorMessage.innerHTML = '';
        setTrabajoTerminado(e.target.value);
    };

    const handleObservacionesChange = (e) => {
        const errorMessage = document.getElementById('error-message-observaciones');
        errorMessage.innerHTML = '';
        setObservaciones(e.target.value);
    };

    const handleImageCapture = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + capturedImages.length > MAX_IMAGES) {
            alert(`Solo se permiten ${MAX_IMAGES} imágenes`);
            return;
        }

        const newImages = files.map(file => {
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newImages).then(images => {
            setCapturedImages(prevImages => [...prevImages, ...images]);
        });
    };

    const handleDeleteImage = (index) => {
        setCapturedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    return (
        <div>
            <Header currentPage="Message" onLogout={handleLogout} onBack={handleBack} />

            <div className="form-container-message">
                <div className='title'>
                    <b>Mensaje</b>
                </div>
                <div className='detail'>
                    {message.message}
                </div>

                <div className='title'>
                    <b>Anomalía</b> &nbsp;{message.id}
                    <img
                        style={{ paddingLeft: '20px' }}
                        src={icon_view} width="50px"
                        alt="Camera Icon"
                        onClick={handleClickView}
                    />
                </div>
                <div className='detail'>
                </div>

                <div className='title'>
                    <b>Trabajo terminado</b>
                    <select
                        onChange={handleTrabajoTerminadoChange}
                        className="dropdown inline-input-si-no"
                    >
                        <option key='-' value='-'>-</option>
                        <option key='0' value='0'>No</option>
                        <option key='1' value='1'>Si</option>
                    </select>
                </div>
                <span className="error-message" id="error-message-trabajo-terminado"></span>
                <div className='detail'>

                </div>

                <div className='flex'>
                    <label className="inline-label">
                        Observaciones:
                    </label>
                    <textarea
                        value={observaciones}
                        onChange={handleObservacionesChange}
                        className="textarea inline-input"
                        rows="5"
                    />
                </div>
                <span className="error-message" id="error-message-observaciones"></span>

                <div className="form-group">
                    <label className="inline-label">
                        <>
                            <img src={icon_camera} width="50px" alt="Camera Icon" />
                            <input
                                type="file"
                                accept="image/*"
                                capture="environment"
                                style={{ display: 'none' }}
                                onChange={handleImageCapture}
                                multiple
                            />
                        </>
                    </label>
                    <div className="images-grid">
                        {capturedImages.map((image, index) => (
                            <div key={index} className="image-container">
                                <img className='image-item' src={image} alt={`Captured ${index + 1}`} />
                                <button onClick={() => handleDeleteImage(index)}>Eliminar</button>
                            </div>
                        ))}
                    </div>

                </div>

                <div className="form-group button-save">
                    <button className="submit-button image-button" onClick={handleSave}>
                        <img src={icon_send_response} width="50px" alt="Save" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Message;
