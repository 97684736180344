import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './Login';
import MainMenu from './MainMenu';
import Anomaly from './Pages/Anomaly';
import AnomalyList from './Pages/AnomalyList';
import MessageList from './Pages/MessageList';
import Message from './Pages/Message';
import { UserProvider } from './context/UserContext';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
  };

  return (
    <div className="App" >
      <UserProvider>
        <Router>
          {isLoggedIn ? (
            <Routes>
              <Route path="/anomaly" element={<Anomaly handleLogout={handleLogout} />} />
              <Route path="/anomaly-list" element={<AnomalyList type='created' handleLogout={handleLogout} />} />
              <Route path="/anomaly-assigned-list" element={<AnomalyList type='assigned' handleLogout={handleLogout} />} />
              <Route path="/message-list" element={<MessageList handleLogout={handleLogout} />} />
              <Route path="/message" element={<Message handleLogout={handleLogout} />} />
              <Route path="/main-menu" element={<MainMenu handleLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/main-menu" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login onLogin={handleLogin} onLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          )}
        </Router>
      </UserProvider>
    </div>
  )
};

export default App;
