import React, { useState, useEffect, useRef } from 'react';
import './Header.css';

function HeaderBanner() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="header-banner">
      <b>Salvatori Anomalias</b>
      <div className="menu-container" ref={dropdownRef}>
        <button className="menu-button" onClick={toggleDropdown}>
          &#x22EE;
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <button className="dropdown-item">Sincronizar</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderBanner;
