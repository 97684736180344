import React, { useState, useEffect } from 'react';

import './AnomalyList.css';
import '../MainMenu.css';
import Header from '../Header';
import axiosInstance from '../axiosConfig'
import { useNavigate } from 'react-router-dom';

function MessageList({ handleLogout }) {
    const [messages, setMessages] = useState([]);

    const getMessages = async () => {
        try {
            const response = await axiosInstance.get(`/php/pwa_get_messages.php`);
            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            console.log('Messages:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error getting zonas:', error);
        }
    }

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const messagesData = await getMessages();
                if (Array.isArray(messagesData)) {
                    setMessages(messagesData);
                } else {
                    console.error('Fetched messages data is not an array:', messagesData);
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, []);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/main-menu');
    };

    const handleSelect = (message) => {
        navigate('/message', { state: { message: message } });
    };

    const finishedMessages = messages.filter(message => message.finished === '1');
    const unfinishedMessages = messages.filter(message => message.finished !== '1');

    const MessageTable = ({ title, messages, handleSelect }) => {
        return (
            <>
                <b>Lista de {title}</b>
                <div className="table-container">
                    {messages.length > 0 ? (
                        <table className='anomaly-table'>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Recibido de</th>
                                    <th>Id</th>
                                    <th>Asunto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {messages.map((message) => (
                                    <tr key={message.id} onClick={() => handleSelect(message)}>
                                        <td>{message.date_created}</td>
                                        <td>{message.from}</td>
                                        <td>{message.incident_id}</td>
                                        <td>{message.message}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>(No hay {title.toLowerCase()})</p>
                    )}
                </div>
            </>
        );
    };
    return (
        <div>
            <Header currentPage="MessageList" onLogout={handleLogout} onBack={handleBack} />

            <div className="form-container text-left">
                {
                    messages.length === 0 ?
                        <p>(Ningún mensaje recibido)</p> :
                        <>
                            <MessageTable title="mensajes con trabajos no finalizados" messages={unfinishedMessages} handleSelect={handleSelect} />
                            <br />
                            <br />
                            <br />
                            <MessageTable title="mensajes con trabajos finalizados" messages={finishedMessages} handleSelect={handleSelect} />
                        </>
                }

            </div>
        </div>
    );
}

export default MessageList;
