

/*

    if (!empty($_POST['app_version'])) {
        $version = $_POST['app_version'];
    }

    //getting the name from request
    $name = $_POST['name'];
    $comment = $_POST['comment'];
    $zone_id = $_POST['zone_id'];
    $lugar_id = $_POST['lugar_id'];
    $date_started = $_POST['date_started'];
    $date_expected = $_POST['date_expected'];
    $incident_category_id = 0;//$_POST['category_id'];
    $incident_status_id = "1";
    $lat = $_POST['lat'];
    $lon = $_POST['lon'];
    $accuracy = substr($_POST['accuracy'], 0, 5);
    $altitude = $_POST['altitude'];
    $utcOffset = $_POST['utcOffset'];
    $urgency_id = $_POST['urgency_id'];
    $user = $_POST['user_id'];
    $user_id_assigned = $_POST['user_id_assigned'];
    $presupuesto_id = '';

    */

    const incidentUploader = {
        getBody: (fields) => {
          return {
            zone_id: fields.zone_id || '',
            lugar_id: fields.lugar_id || '',
            date_started: fields.date_started || '',
            date_expected: fields.date_expected || '',
            incident_category_id: fields.incident_category_id || 0,
            incident_status_id: fields.incident_status_id || "1",
            lat: fields.lat || '',
            lon: fields.lon || '',
            accuracy: fields.accuracy ? fields.accuracy.toString().substring(0, 5) : '',
            altitude: fields.altitude || '',
            utcOffset: fields.utcOffset || '',
            urgency_id: fields.urgency_id || '',
            user: fields.user_id || '',
            user_id_assigned: fields.user_id_assigned || '',
            presupuesto_id: fields.presupuesto_id || '',
            name: fields.name || '',
            comment: fields.comment || '',
          };
        }
      };

export default incidentUploader;