import { openDB } from 'idb';

const dbPromise = openDB('pwa-db', 1, {
    upgrade(db) {
        db.createObjectStore('formData', { keyPath: 'id', autoIncrement: true });
    },
});

export const saveFormDataInIdb = async (data) => {
    const db = await dbPromise;
    await db.add('formData', data);
    console.log('Data saved to the store', data);
};

export const getFormDataFromIdb = async () => {
    const db = await dbPromise;
    return await db.getAll('formData');
};

export const deleteFormData = async (id) => {
    const db = await dbPromise;
    try {
        await db.delete('formData', id);
    } catch (error) {
        console.error(error);
    }
};
