import React, { useState, useEffect } from 'react';

import './AnomalyList.css';
import '../MainMenu.css';
import Header from '../Header';
import axiosInstance from '../axiosConfig'
import { useNavigate } from 'react-router-dom';

function AnomalyList({ type, handleLogout }) {
    const [anomalies, setAnomalies] = useState([]);

    const getAnomalies = async () => {
        try {
            const response = await axiosInstance.get(`/php/pwa_get_incidents.php?type=${type}`);
            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            console.log('Anomalies:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error getting incidents:', error);
        }
    }

    useEffect(() => {
        const fetchAnomalies = async () => {
            try {
                const anomaliesData = await getAnomalies();
                if (Array.isArray(anomaliesData)) {
                    setAnomalies(anomaliesData);
                } else {
                    console.error('Fetched anomalies data is not an array:', anomaliesData);
                }
            } catch (error) {
                console.error('Error fetching anomalies:', error);
            }
        };

        fetchAnomalies();
    }, []);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/main-menu');
    };

    const handleSelect = (anomaly) => {
        navigate('/anomaly', { state: { anomaly } });
    };

    const finishedAnomalies = anomalies.filter(anomaly => anomaly.finished === 1);
    const unfinishedAnomalies = anomalies.filter(anomaly => anomaly.finished !== 1);

    const AnomalyTable = ({ title, anomalies, handleSelect }) => {
        return (
            <>
                Lista de {title}
                <div className="table-container">
                    {anomalies.length > 0 ? (
                        <table className='anomaly-table'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Descripción</th>
                                    <th>Asignada a</th>
                                    <th>Etapa cumplida</th>
                                </tr>
                            </thead>
                            <tbody>
                                {anomalies.map((anomaly) => (
                                    <tr key={anomaly.id} onClick={() => handleSelect(anomaly)}>
                                        <td>{anomaly.id}</td>
                                        <td>{anomaly.name}</td>
                                        <td>{anomaly.user_to}</td>
                                        <td>{anomaly.stage_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>(No hay {title.toLowerCase()})</p>
                    )}
                </div>
            </>
        );
    };
    console.log('anomalies:', anomalies);
    return (
        <div>
            <Header currentPage="Anomaly" onLogout={handleLogout} onBack={handleBack} />

            <div className="form-container text-left">
                {type === 'created' ?
                    anomalies.length === 0 ?
                        <p>(No hay anomalias creadas)</p> :
                        <>
                            Lista de anomalias creadas
                            <div className="table-container">
                                <table className='anomaly-table'>
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Descripción</th>
                                            <th>Comentario</th>
                                            <th>Zona</th>
                                            <th>Lugar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {anomalies.map((anomaly) => (
                                            <tr key={anomaly.id} onClick={() => handleSelect(anomaly)}>
                                                <td>{anomaly.date}</td>
                                                <td>{anomaly.name}</td>
                                                <td>{anomaly.comment}</td>
                                                <td>{anomaly.zone_name}</td>
                                                <td>{anomaly.lugar_name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>

                    :

                    anomalies.length === 0 ?
                        <p>(No hay anomalias asignadas)</p> :
                        <>
                            <AnomalyTable title="anomalías finalizadas" anomalies={finishedAnomalies} handleSelect={handleSelect} />
                            <br />
                            <br />
                            <AnomalyTable title="anomalías no finalizadas" anomalies={unfinishedAnomalies} handleSelect={handleSelect} />
                        </>
                }

            </div>
        </div>
    );
}

export default AnomalyList;
