import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import './MainMenu.css';
import Header from './Header';

function MainMenu({ handleInicio, handleLogout }) {
  const navigate = useNavigate();

  const handleClickAnomaly = () => {
    navigate('/anomaly');
  };

  const handleClickCreatedAnomalies = () => {
    navigate('/anomaly-list');
  };
  
  const handleClickMessages = () => {
    navigate('/message-list');
  };  

  const handleClickAssigneddAnomalies = () => {
    navigate('/anomaly-assigned-list');
  };

  return (
    <div>
      <Header currentPage="MainMenu" onLogout={handleLogout} />
      <div className="form-container centered">
        <p><button className="submit-button" onClick={handleClickAnomaly}>NUEVA ANOMALIA</button></p>
        <p><button className="submit-button" onClick={handleClickMessages}>MENSAJES</button></p>
        <p><button className="submit-button" onClick={handleClickAssigneddAnomalies}>ANOMALIAS ASIGNADAS POR MI</button></p>
        <p><button className="submit-button" onClick={handleClickCreatedAnomalies}>ANOMALIAS CREADAS POR MI</button></p>
      </div>
    </div >
  );
}

export default MainMenu;
